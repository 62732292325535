<template>
  <v-container class="pa-10" fluid>
    <v-row class="ml-7" style="width: 96%">
      <v-col cols="12">
        <v-card>
          <v-col cols="12">
            <v-card class="mt-10 mb-10">
              <v-data-table
                :headers="headers"
                :items="prices"
                :loading="loading"
                loading-text="Please wait..."
                no-data-text="Price is not available"
                class="elevation-1"
              >
                <template v-slot:top>
                  <v-toolbar elevation="2" style="background: #f5f5f5">
                    <v-row>
                      <p
                        class="mt-5 mb-2 ml-1 font-weight-bold d-inline-block text-truncate"
                        style="max-width: 500px"
                      >
                        Product Title : sfjkgjkdsgjknb
                      </p>
                    </v-row>
                  </v-toolbar>
                </template>

                <template v-slot:item.addprice="{ item }">
                  <v-btn icon @click="AddPrice(item)" color="primary">
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="editdialog" width="500">
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Privacy Policy
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="editItem.new_price"
                label="Product Selling Price"
                required
                type="number"
                :rules="[(v) => !!v || 'Selling Price is required']"
                outlined
                dense
              ></v-text-field
            ></v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updatePrice" elevation="0">
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" centered>
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      prices: [],
      headers: [
        { text: "SKU", value: "SKU", sortable: false },
        { text: "Quantity", value: "quantity", sortable: false },
        { text: "Purchase Price", value: "purchase_price", sortable: false },
        { text: "Selling Price", value: "new_price", sortable: false },
        { text: "", value: "adquantity", sortable: false },
        { text: "", value: "addprice", sortable: false },
      ],
      loading: true,
      editdialog: false,
      editItem: {},
      text: "",
      snackbar: false,
    };
  },
  methods: {
    initialize() {
      axios
        .get(`productdetails/showspec/${this.$route.query.id}/`)
        .then((response) => {
          if (Array.isArray(response.data.data)) {
            this.prices = response.data.data;
            this.prices = [
              {
                SKU: 2735,
                quantity: 23,
                purchase_price: 875,
                new_price: 84756,
              },
            ];
          }
          this.prices = [
            {
              id: 12,
              SKU: 2735,
              quantity: 23,
              purchase_price: 875,
              new_price: 84756,
            },
          ];
          this.loading = false;
        });
    },

    AddPrice(item) {
      Object.assign(this.editItem, item);
      this.editdialog = true;
    },

    updatePrice() {
      // make api call
      let index = this.prices.findIndex(
        (element) => element.id == this.editItem.id
      );
      this.prices[index].new_price = this.editItem.new_price;
      this.editdialog = false;
      this.text = "Selling price updated successfully";
      this.snackbar = true;
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.19rem;
  height: 48px;
}
</style>